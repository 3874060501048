jQuery(document).ready(function ($) {
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  let perPage = 3;
  let currentPageCount = 1;
  let totalPageCount = 0;

  window.onload = function () {
    if (window.innerWidth > 989) {
      // desktop
      perPage = 3;
      totalPageCount = Math.ceil(slideTotalCount / perPage);
      $(".js-update-page-count").html(currentPageCount + "/" + totalPageCount);
    }
    if (window.innerWidth > 768 && window.innerWidth < 989) {
      // tablet
      perPage = 2;
      totalPageCount = Math.ceil(slideTotalCount / perPage);
      $(".js-update-page-count").html(currentPageCount + "/" + totalPageCount);
    }
    if (window.innerWidth < 768) {
      // mobile
      perPage = 1;
      totalPageCount = Math.ceil(slideTotalCount / perPage);
      console.log("total page count", totalPageCount);
      $(".js-update-page-count").html(currentPageCount + "/" + totalPageCount);
    }
  };

  if (slideTotalCount) {
    totalPageCount = Math.ceil(slideTotalCount / perPage);
    // When the next slide is clicked, update the count to match
    $(".next-slide.js-update").on("click", function (event) {
      if (currentPageCount !== totalPageCount) {
        currentPageCount += 1;
        $(".js-update-page-count").html(
          currentPageCount + "/" + totalPageCount
        );
      } else if (currentPageCount === totalPageCount) {
        currentPageCount = 1;
        $(".js-update-page-count").html(
          currentPageCount + "/" + totalPageCount
        );
      }
    });

    // When the previous button is pressed, remove 1 from the count
    $(".prev-slide.js-update").on("click", function (event) {
      if (currentPageCount > 1) {
        currentPageCount -= 1;
        $(".js-update-page-count").html(
          currentPageCount + "/" + totalPageCount
        );
      } else {
        currentPageCount = totalPageCount;
        $(".js-update-page-count").html(
          currentPageCount + "/" + totalPageCount
        );
      }
    });
  }

  // Listen for resize events
  window.addEventListener(
    "resize",
    function (event) {
      // If there's a timer, cancel it
      if ("undefined" !== typeof timeout) {
        window.cancelAnimationFrame(timeout);
      }

      // Setup the new requestAnimationFrame()

      timeout = window.requestAnimationFrame(function () {
        // Run our scroll functions

        function numberUpdater(perPage) {
          currentPageCount = parseInt(
            $(".active-slide")
              .find(".slide-entry")
              .attr("class")
              .replace(/[^0-9]/gi, "")
          );
          currentPageCount = Math.ceil(currentPageCount / perPage);
          totalPageCount = Math.ceil(slideTotalCount / perPage);
          $(".js-update-page-count").html(
            currentPageCount + "/" + totalPageCount
          );
        }

        if (window.innerWidth > 989) {
          // desktop
          numberUpdater(3);
        }
        if (window.innerWidth > 768 && window.innerWidth < 989) {
          // tablet
          numberUpdater(2);
        }
        if (window.innerWidth < 768) {
          // mobile
          numberUpdater(1);
        }
      });
    },
    false
  );

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
});
